<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <Header />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class=" align-items-center auth-bg px-2 p-lg-5"
      >
        <div class="row">
          <div class=" mx-auto col-12 margin-top-icon-sm">
            <MediaIcon />
          </div>
          <div class="px-xl-2 mx-auto col-12 text-margin-top-sm">
            <b-card-title class="mb-1">
              Forgot Username? 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              We will send an OTP code to your email address for security in forgetting username
            </b-card-text>

            <validation-observer
              ref="forgotUsername"
              class="auth-forgot-password-form mt-2"
              @submit.prevent="forgotUsername"
            >
              <b-form class="auth-forgot-password-form mt-2">
                <!-- username -->
                <b-form-group
                  label="Email or mobile"
                  label-for="forgot-password-userName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email_or_mobile"
                    vid="email_or_mobile"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="forgot-password-userName"
                        v-model="emailOrMobile"
                        :state="requiredState"
                        name="email_or_mobile"
                        placeholder="Enter email or mobile"
                        type="text"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isDisabled"
                >
                  Continue
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-col>
      <!-- /Forgot password-->

      <!-- /footer-->
      <b-col
        lg="12"
        class="mt-auto"
      >
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BLink, BRow, BFormInvalidFeedback, BInputGroup,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

import Header from '@core/components/landding-page/Header.vue'
import CoverImage from '@core/components/landding-page/CoverImage.vue'
import MediaIcon from '@core/components/landding-page/MediaIcon.vue'
import Footer from '@core/components/landding-page/Footer.vue'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'ForgotUsername',
  components: {
    BInputGroup,
    BCol,
    Footer,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    MediaIcon,
    CoverImage,
    BFormGroup,
    BFormInput,
    BRow,
    BCardText,
    BLink,
    Header,
    BCardTitle,
    'b-form-invalid-feedback': BFormInvalidFeedback,
  },

  data() {
    return {
      required,
      errors: {},
      isDisabled: true,
      requiredState: null,
    }
  },

  computed: {
    // ...mapState('forgot-username', ['isDisabled']),
    emailOrMobile: {
      get() {
        return this.$store.state['forgot-username'].emailOrMobile
      },
      set(value) {
        this.$store.commit('forgot-username/setEmailMobile', value)
        this.$store.commit('forgot-username/isAllowToSubmitForm', value)
      },
    },
  },

  watch: {
    emailOrMobile(val) {
      if (val === '') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
  },

  methods: {
    ...mapMutations('forgot-username', ['setEmailMobile']),

    forgotUsername() {
      this.$store.dispatch('forgot-username/submitForgotUsername')
    },
  },
}
</script>

<style scoped lang="scss">

</style>
